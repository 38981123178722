import React, { Component } from "react";
import {
  Form,
  Input,
  Dropdown,
  Button,
  TextArea,
  Icon,
  Confirm,
  Table,
  Loader,
  Dimmer,
  Segment,
  Modal,
  Header
} from "semantic-ui-react";

import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import "./ReceivePage.css";
import _ from "underscore";
import moment from "moment";
// import Cookies from "universal-cookie";
import Errorpop from "../error/Errorpop";
var ES6Promise = require("es6-promise");
ES6Promise.polyfill();
var axios = require('axios');


// const cookies = new Cookies();
var id = 0;
var justadded = false;
var prevTime = null;


const CommentSection = React.memo(({ value, onChange }) => {
  return (
      <Form>
          <Header 
              as='h4' 
              style={{ marginTop: '20px' }}
          >
              Additional Comments
          </Header>
          <TextArea
              placeholder='Enter any additional comments...'
              value={value}
              onChange={(e, data) => onChange(data.value)}
              style={{ minHeight: 100 }}
          />
      </Form>
  );
});

export default class ReceivePage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      custOptions: [],
      testOptions: [],
      unitOptions: [],
      tempOptions: [],
      locOptions:[],
      allLoc:[],
      SampleDetail: null,
      initData: this.props.initData,
      SampleData: [],
      custID: null,
      items: 0,
      receiveDate: moment().format("YYYY-MM-DD"),
      addButtonEnabled: false,
      printButtonEnabled: true,
      saveButtonEnabled: true,
      cancelButtonEnabled: true,
      userOptions: [],
      userID: '',
      confirmResetOpen: false,
      confirmDeleteOpen : false,
      grpOptDisabled: false,
      sampleDate: moment().format("YYYY-MM-DD"),
      sampleTime: '00:00',
      analysisID:[],
      analysisNM:[],
      UnitID:[],
      UnitNM:[],
      TempID:[],
      TempNM:[],
      prev_ref:0,
      currentSeq:1,
      loadingButton: false,
      loadingSave: false,
      loaderActive: false,
      pickLocOpen: false,
      testEditDisabled: false,
      locSelected: 0,
      saveConfirm: false,
      filterString: '',
      defaultArea:1,
      showOptionButton:false,
      disableSaveOpt:true,
      submissionComment: '',  // Additional Comment.
    };
    id = 0;
    justadded = false;
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.pickCustomer = this.pickCustomer.bind(this);
    this.pickAnalysis = this.pickAnalysis.bind(this);
    this.pickUnit = this.pickUnit.bind(this);
    this.pickTemp = this.pickTemp.bind(this);
    this.saveDesc = this.saveDesc.bind(this);
    this.saveDate = this.saveDate.bind(this);
    this.renderArrays = this.renderArrays.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handlePrintClick = this.handlePrintClick.bind(this);
    this.AddUnit = this.AddUnit.bind(this);
    this.addForm = this.addForm.bind(this);
    this.initialize=this.initialize.bind(this);
    this.handleSaveClick=this.handleSaveClick.bind(this);
    this.addTest=this.addTest.bind(this);
    this.removeTest=this.removeTest.bind(this);
    this.errorpop = React.createRef();

  }

  
  
  writeLog = async() => {
    const response = await fetch("/api/printlog", {
      method: "POST",
      headers: {
        Accept: "application/Blob",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({payload:this.state.SampleData})
    });
    const body = await response.blob();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  pickCustomer = (e, data) => {
    //console.log(JSON.stringify(data))  ;
    console.log(data.value);
    this.setState({
      custID: data.value,
      addButtonEnabled: false,
      printButtonEnabled: false,
      cancelButtonEnabled: false
    });
  };

  getPDFoutput = async payload => {
    const response = await fetch("/api/genreceiptpdf", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handlePrintClick() {
    var filename =
      this.state.custID.toString() +
      "_" +
      moment().format("YYMMDDHHmm") +
      ".pdf";
    // console.log(this.state.custOptions);
    // console.log(this.state.custID);
    let detail=this.state.SampleData.slice();
    detail.forEach((data,index)=>{
      detail[index].loc_nm=_.where(this.state.allLoc,{loc_cd:data.loc_cd})[0].loc_nm;
    })
    // console.log(_.where(this.state.custOptions, { value: this.state.custID }))
    var pdfPayload = {
      filename: filename,
      custID: this.state.custID,
      custNM: this.props.custnm,
      Detail: detail,
      comment: this.state.submissionComment 
    };
    console.log(pdfPayload);
    // console.log(pdfPayload);
    this.getPDFoutput(pdfPayload).then(res => {
      /*const file = new Blob(
    [res.data], 
    {type: 'application/pdf'});*/
      //Build a URL from the file
      console.log(res);
      const fileURL = URL.createObjectURL(res);
      // console.log(res);
      // console.log(fileURL);
      //Open the URL on new Window
      window.open(fileURL);
    });
  }

  addTest(e) {
    e.preventDefault();
    if (this.props.custid===33) this.state.analysisID.push("t31"); 
    else
    if (this.props.custid===130 || this.props.custid===131 || this.props.custid===133 ) this.state.analysisID.push("t31"); 
    else  this.state.analysisID.push("t0");

    this.state.UnitID.push("u1");
    this.state.TempID.push("p1");
    if (this.props.custid===33)
    this.state.analysisNM.push(_.where(this.state.testOptions, { value: "t31" })[0].text);
    else 
    if (this.props.custid===130 || this.props.cust===131 || this.props.custid=== 133)
    this.state.analysisNM.push(_.where(this.state.testOptions, { value: "t31" })[0].text);
    else
    this.state.analysisNM.push(_.where(this.state.testOptions, { value: "t0" })[0].text);

    this.state.UnitNM.push(_.where(this.state.unitOptions, { value: "u1" })[0].text);
    this.state.TempNM.push(_.where(this.state.tempOptions, { value: "p1" })[0].text) ;
this.setState({confirmResetOpen: false});
   
  }

  removeTest(e,index) {
    e.preventDefault();
    
    this.state.analysisID.splice(index,1);
    this.state.UnitID.splice(index,1);
    this.state.TempID.splice(index,1);
    this.state.analysisNM.splice(index,1);
    this.state.UnitNM.splice(index,1);
    this.state.TempNM.splice(index,1);
    this.setState({confirmResetOpen: false});
  }

  pickAnalysis = (data, index) => {
    var testOptions = this.state.testOptions;
    var analysisID=this.state.analysisID;
    var analysisNM=this.state.analysisNM;
     analysisID[index]=data.value;
  analysisNM[index]=_.where(testOptions, {value: data.value})[0].text;
  this.setState({analysisID : analysisID,
           analysisNM : analysisNM});
  console.log(analysisID);
  console.log(analysisNM);
    // dflt_test=_.where(this.state.testOptions, {
    //   value: data.value
    // })[0].code;
  };

  updateAnalysisArea = (data) => {
        this.setState({defaultArea : data.value});

  };


  pickUnit = (data,index ) => {

    var UnitID=this.state.UnitID;
    UnitID[index]=data.value;
    var UnitNM=this.state.UnitNM;
    if (_.where(this.state.unitOptions, { value: data.value }).length > 0) {
        UnitNM[index]=_.where(this.state.unitOptions, {
          value: data.value
        })[0].text;
      } else {
       UnitNM[index]=data.value
      }
    this.setState({UnitID:UnitID,UnitNM:UnitNM});
    console.log(UnitID);
    console.log(UnitNM);
  };

  pickTemp = (data, index) => {
    
    var TempID=this.state.TempID;
    TempID[index]=data.value;
    var TempNM=this.state.TempNM;
    if (_.where(this.state.tempOptions, { value: data.value }).length > 0) {
      TempNM[index]=_.where(this.state.tempOptions, {
        value: data.value
      })[0].text;
    } else {
      TempNM[index]=data.value
    }
  this.setState({TempID:TempID,TempNM:TempNM});
  // console.log(TempID);
  // console.log(TempNM);
  };

  saveSeq = (event,data,item,i) => {
    var newData = this.state.SampleData;
    if (data.value==='' || !isNaN(parseInt(data.value))) {  
    newData[i].seqno = data.value;
    justadded = false;
    this.setState({ SampleData: newData,currentSeq:parseInt(data.value)+1 });
    }
  }

  changeLoc = (e,data,item) => {
  //   let oldloccd=item.loc_cd;
  //   let newloccd=data.value;
  //   var totalselected=this.state.locSelected;
  //  for (var i in this.state.allLoc) 
  //  if (this.state.allLoc[i].loc_cd===oldloccd && _.where(this.state.SampleData,{loc_cd:oldloccd}).length===1) { this.state.allLoc[i].selected=false; totalselected--}
  //  else if (this.state.allLoc[i].loc_cd===newloccd && _.where(this.state.SampleData,{loc_cd:newloccd}).length===0) {this.state.allLoc[i].selected=true;totalselected++}
    //console.log(data.value);
    item.Desc='';
    item.loc_cd=data.value.sort();
    for (var i in item.loc_cd) {
    // console.log(_.where(this.state.allLoc,{loc_cd:data.value[i]}))
    item.Desc+=item.loc_cd?(item.Desc===''?'':'\n')+_.where(this.state.allLoc,{loc_cd:item.loc_cd[i]})[0].loc_nm+' '
    +_.where(this.state.allLoc,{loc_cd:item.loc_cd[i]})[0].loc_desc:'';
    }
    this.setState({ SampleData: this.state.SampleData });
  }

  saveDesc = (event, data, item, i) => {
    // console.log(event);
    // console.log(data);
    var newData = this.state.SampleData;
    newData[i].Desc = data.value;
    justadded = false;
    this.setState({ SampleData: newData });
  };

  saveArea = (event, data, item, i) => {
    // console.log(event);
    // console.log(data);renderArrays
    var newData = this.state.SampleData;
    if (data.value && !isNaN(data.value))
    {
    newData[i].analysisArea = data.value?data.value:1;
    justadded = false;
    this.setState({ SampleData: newData });
    }
  };



  saveRefnum = (e, data, item, i) => {
    var newData = this.state.SampleData;
    if (data.value==='' || !isNaN(parseInt(data.value))) {  
    newData[i].ref_num = data.value;
    justadded = false;
    newData[i].err_ref_num=false;
    this.setState({ SampleData: newData, prev_ref : data.value }, () => this.renderArrays());
    }
    else {
      newData[i].err_ref_num=true;
      justadded = false;
      this.setState({ SampleData: newData});
    }
  }

  commitChange = (event, item, i) => {
   //console.log(event);
    // console.log(item);
    this.renderArrays("U", i, item.labelID)
  };

  saveDate = (data, item, i) => {
    console.log(data);
    var newData = this.state.SampleData;
    newData[i].SampleDate = data+' '+moment(newData[i].SampleDate).format('HH:mm');
    justadded = false;
    prevTime=moment(newData[i].SampleDate ).format('YYYY-MM-DD HH:mm');
    this.setState({ SampleData: newData });
  };


  saveTime = (data, item, i) => {
    console.log(data);
    var newData = this.state.SampleData;
    newData[i].SampleDate = moment(newData[i].SampleDate).format('YYYY-MM-DD')+' '+data;
    justadded = false;
  //  console.log(newData[i].SampleDate);
    prevTime=moment(newData[i].SampleDate ,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
    console.log(prevTime);
    this.setState({ SampleData: newData },this.renderArrays("U", i, item.labelID));
  };


saveSampleDate = (data) => {
  this.setState({ sampleDate: data });
}

  saveRecvDate = (data) => {
    //console.log(data);
    this.setState({ receiveDate: data });
  };

  saveStartSeq = (data) =>{
    console.log(parseInt(data.value));
    if (data.value==='' || !isNaN(data.value)) {  
    this.setState({ currentSeq:data.value });
    }
  }

  AddUnit = (e, { value }) => {
    var oldUnits = this.state.unitOptions;
    oldUnits.push({ text: value, value:value });
    // console.log(oldUnits);
    this.setState({
      unitOptions: oldUnits
    });
  };

  AddTemp = (e, { value }) => {
    var oldTemp = this.state.tempOptions;
    oldTemp.push({ text: value, value:value });
    this.setState({
      tempOptions: oldTemp
    });
  };
  // Need to rewrite render arrays to handle update and delete scenario.

  renderArrays(opt, key , id) {
    if (opt === "U") {
    
    var newData = this.state.SampleData;
    // console.log(newData);
    var payload = {
      id: newData[key].labelID,
      custid: this.state.custID,
      userid: this.state.userKey,
      testid: newData[key].analysisID,
      unitNM: newData[key].UnitNM,
      tempNM: newData[key].TempNM,
      recvDT: this.state.receiveDate,
      sampleDT: newData[key].SampleDate,
      desc: (newData[key].Desc?newData[key].Desc:'').replace("\\","\\\\").replace("'","\\'"),
      //desc: newData[key].Desc,
      unitID: newData[key].UnitID,
      tempID: newData[key].TempID,
      analysisArea: newData[key].analysisArea,
      loc_cd:newData[key].loc_cd.join(','),
      seqno: parseInt(newData[key].seqno)
    };

//        this.setState({ SampleData: newData }, () => this.refreshTable());
axios.post("/api/updSubmission",payload)
  .then(result => {
    if (result.data.status !== "ERROR") this.refreshTable();
    else 
     { console.log("error" + JSON.stringify(result));
     this.errorpop.current.handleOpen();
    //  this.delSample(key);
    }
  })
  .catch(function(error) {
    console.log(error);
  });

} else this.refreshTable();
}

  refreshTable = () => {
    // console.log("sample data:");
    // console.log(this.state.SampleData);
    
  }
  

// The following code block handle the delete of a single entry from the 
// form

  showConfirmDelete=(i)=>{
       this.setState({confirmDeleteOpen:true, confirmDeleteNumber:i}); 
  }

  handleCancelDelete=()=>{
    this.setState({confirmDeleteOpen:false}); 
  }

  handleConfirmDelete=()=>{
    this.setState({confirmDeleteOpen:false}); 
    this.delSubmission(this.state.confirmDeleteNumber);
  }

  delSubmission = i => {
    var newData = this.state.SampleData;
    axios.post("/api/delSubmission",{payload:"'"+this.state.SampleData[i].labelID+"'"}).then((result)=>{
      if (result.data.status !== "ERROR") {
        if (newData[i].labelID.endsWith('-D'))
        newData.forEach((data,index)=>{
          if (data.labelID+'-D'=== newData[i].labelID)
            data.dupInd='N'
        })
      
        for (var j=newData.length;j--;)
         if (newData[j].labelID===this.state.SampleData[i].labelID || 
          newData[j].labelID===this.state.SampleData[i].labelID+'-D')
        { newData.splice(j, 1);
          id = id - 1; 
          break;
      }
      
        justadded = false;
        // console.log(newData);
        this.setState({ SampleData: newData }, () => this.refreshTable());
      }
      else 
      this.errorpop.current.handleOpen();
    });
  };

handleSaveClick = () => {
 
 if (this.state.SampleData.length>0)
 { this.setState({loadingSave:true});

   var requests=[];
   var newData = this.state.SampleData;
 for (var key in newData) 
 {
    // console.log(newData);
    var payload = {
      id: newData[key].labelID,
      custid: this.state.custID,
      userid: this.state.userKey,
      testid: newData[key].analysisID,
      unitNM: newData[key].UnitNM,
      tempNM: newData[key].TempNM,
      recvDT: this.state.receiveDate,
      sampleDT: newData[key].SampleDate,
      desc:  (newData[key].Desc?newData[key].Desc:'').replace("\\","\\\\").replace("'","\\'"),
      //desc: newData[key].Desc,
      unitID: newData[key].UnitID,
      tempID: newData[key].TempID,
      analysisArea: newData[key].analysisArea,
      loc_cd:newData[key].loc_cd.join(","),
      // loc_cd:"abc",
      seqno: parseInt(newData[key].seqno)
    };

//        this.setState({ SampleData: newData }, () => this.refreshTable());
 requests.push(axios.post("/api/updSubmission",payload))
  }

  axios.all(requests).then(results => {
    // if (result.data.status !== "ERROR") this.refreshTable();
    // else 
    //  { console.log("error" + JSON.stringify(result));
    //  this.errorpop.current.handleOpen();
    // //  this.delSample(key);
    // }
    var success=true;
    for (var res in results) {if (results[res].data.status==='ERROR') success=false};
    if (success) {
    this.setState({loadingSave:false});
    this.setState({saveButtonEnabled:true, saveConfirm:true, saveStep: 1})
    }
    else 
         { console.log("error" + JSON.stringify(results));
     this.errorpop.current.handleOpen();
     this.setState({loadingSave:false});
    }
  })
  .catch(function(error) {
    console.log(error);
    this.setState({loadingSave:false});
  });




 }
else return;
  
}
  
submitSample = () => {
  this.setState({loadingSave:true})
//  this.handlePrintClick();
//   this.setState({saveButtonEnabled:true,  loadingSave: true});
  var saveIDs=_.map(_.pluck(this.state.SampleData,"labelID"),function(item){return "'"+item+"'"}).join(",");
  var filename =
  "FSR_SUB_"+ this.state.custID.toString() +
  "_" +
  moment().format("YYMMDDHHmm") +
  ".pdf";
  axios.post("/api/saveSubmission",{payload:saveIDs,filename:filename}).then((result)=>{
if (result.data.status!=='ERROR')
{
  let detail=this.state.SampleData.slice();
  detail.forEach((data,index)=>{
    detail[index].loc_nm='';
    if (data.loc_cd!==null) 
       for (var i in data.loc_cd)
        detail[index].loc_nm=detail[index].loc_nm+(detail[index].loc_nm===''?'':'\n')+_.where(this.state.allLoc,{loc_cd:data.loc_cd[i]})[0].loc_nm;
  })

  var fileID=this.state.custID.toString().padStart(3,'0') + moment().format("YYMMDDHHmm")
  // console.log(_.where(this.state.custOptions, { value: this.state.custID }))
  var pdfPayload = {
    filename: filename,
    fileID: fileID,
    custID: this.state.custID,
    custNM: this.props.custnm,
    Detail: detail,
    comment: this.state.submissionComment 
  };
  console.log(pdfPayload);
  // console.log(pdfPayload);
  this.getPDFoutput(pdfPayload).then(res => {
console.log(res);
if (res.STATUS==='SUCCESS') 
this.setState({saveStep:2,pdfName:filename});
else this.setState({saveStep:3});
if (this.props.custid===33)
this.setState(
  {
    SampleDetail: null,
    SampleData: [],
    items: 0,
    receiveDate: moment().format('YYYY-MM-DD'),
    addButtonEnabled: false,
    printButtonEnabled: true,
    cancelButtonEnabled: true,
    saveButtonEnabled: true,
    testEditDisabled: false,
grpOptDisabled:false,
loadingSave: false,
currentSeq: 1,
analysisID: ["t31"],
 UnitID: ["u1"],
 TempID: ["p1"],
 analysisNM: [_.where(this.state.testOptions, { value: "t31" })[0].text],
 UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
 TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text],
 submissionComment: '',  
  });
else 
if (this.props.custid===130 || this.props.custid===131 || this.props.custid===133)
this.setState(
  {
    SampleDetail: null,
    SampleData: [],
    items: 0,
    receiveDate: moment().format('YYYY-MM-DD'),
    addButtonEnabled: false,
    printButtonEnabled: true,
    cancelButtonEnabled: true,
    saveButtonEnabled: true,
    testEditDisabled: false,
grpOptDisabled:false,
loadingSave: false,
currentSeq: 1,
analysisID: ["t31"],
 UnitID: ["u1"],
 TempID: ["p1"],
 analysisNM: [_.where(this.state.testOptions, { value: "t31" })[0].text],
 UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
 TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text],
 submissionComment: '',  
  });
else
this.setState(
  {
    SampleDetail: null,
    SampleData: [],
    items: 0,
    receiveDate: moment().format('YYYY-MM-DD'),
    addButtonEnabled: false,
    printButtonEnabled: true,
    cancelButtonEnabled: true,
    saveButtonEnabled: true,
    testEditDisabled: false,
grpOptDisabled:false,
loadingSave: false,
currentSeq: 1,
analysisID: ["t0"],
 UnitID: ["u1"],
 TempID: ["p1"],
 analysisNM: [_.where(this.state.testOptions, { value: "t0" })[0].text],
 UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
 TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text],
 submissionComment: '',  
  });

})
}
else 
this.errorpop.current.handleOpen();
  })
}

getForm = async(filename) => {
  const response = await fetch("/api/getPDF?filename="+filename, {
      method: "GET",
      headers: {
        Accept: "application/blob",
        "Content-Type": "application/json"
      }
  })
  const body = await response.blob();
  if (response.status !== 200) throw Error(body.message);
  console.log(body);
  return body;
}

downloadForm = () => {
  this.getForm(this.state.pdfName).then(
    res=>{

      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res, this.state.pdfName);
    } else {
        const fileURL = URL.createObjectURL(res);
        var anchor = document.createElement('a');
        document.body.appendChild(anchor);
        anchor.setAttribute("type", "hidden");
        anchor.download = this.state.pdfName;
        anchor.href=fileURL;
        anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
         anchor.click();
    }
    }
)
}


  // The following code lbock handel the delete of all entries
  handleCancelClick() {
    var delIDs="";
    if (this.state.SampleData.length>0)
     delIDs=_.map(_.pluck(this.state.SampleData,"labelID"),function(item){return "'"+item+"'"}).join(",");

    axios.post("/api/delSubmission",{payload:delIDs}).then(()=>{
      this.state.allLoc.map((data,index)=>data.selected=0);
      prevTime=null;
    this.setState(
      {
        SampleDetail: null,
        SampleData: [],
        items: 0,
        receiveDate: moment().format('YYYY-MM-DD'),
        sampleDate: moment().format('YYYY-MM-DD'),
        addButtonEnabled: false,
        printButtonEnabled: true,
        cancelButtonEnabled: true,
        saveButtonEnabled: true,
grpOptDisabled:false,
currentSeq: 1,
submissionComment: '',  
      },
      () => this.renderArrays("C", 0)
    );
    })
  }
  
  showConfirmCancel=()=>{
    this.setState({confirmResetOpen:true}); 
}

handleCancelCancel=()=>{
 this.setState({confirmResetOpen:false}); 
}

handleConfirmCancel=()=>{
 this.setState({showOptionButton:false, confirmResetOpen:false, testEditDisabled: false}); 
 if (this.props.custid===33) this.setState({analysisID: ["t31"],
 UnitID: ["u1"],
 TempID: ["p1"],
 analysisNM: [_.where(this.state.testOptions, { value: "t31" })[0].text],
 UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
 TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});
 else  if (this.props.custid===130 || this.props.custid===131 || this.props.custid===133 ) this.setState({analysisID: ["t31"],
 UnitID: ["u1"],
 TempID: ["p1"],
 analysisNM: [_.where(this.state.testOptions, { value: "t31" })[0].text],
 UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
 TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});
 else
 this.setState({analysisID: ["t0"],
 UnitID: ["u1"],
 TempID: ["p1"],
 analysisNM: [_.where(this.state.testOptions, { value: "t0" })[0].text],
 UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
 TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});

 this.handleCancelClick();
}

// The following code block handel the copy of an entry
 handleCopyClick = (item) => {
   console.log(item);
   var newData = {
    seqno: item.seqno+0.1,
    labelID:
      item.labelID+'-D',
    analysisID: item.analysisID,
    analysisArea: item.analysisArea,
    UnitID: item.UnitID,
    TempID: item.TempID,
    analysisNM: item.analysisNM,
    UnitNM: item.UnitNM,
    TempNM: item.TempNM,
    SampleDate: item.SampleDate,
    Desc: item.Desc,
    loc_cd: item.loc_cd
  };
  let payload="("+newData.seqno+",'"
  +newData.labelID+"','"+this.props.custid+"','','"+newData.analysisID+"','"+newData.UnitNM+"','"+newData.TempNM
  +"',null,'"+newData.SampleDate+"','"+newData.Desc.replace("\\","\\\\").replace("'","\\'")+"','PRESUBMIT','"+newData.UnitID+"','"+newData.TempID+"','"+newData.loc_cd.join(',')+"',"+newData.analysisArea+")"

  axios.post("/api/addSubmission",{payload:payload}).then(result => {

      if (result.data.status !== "ERROR") this.addForm(newData);
      else {  this.errorpop.current.handleOpen();
        console.log("error" + JSON.stringify(result));
      }
    })
    .catch(function(error) {
      console.log(error);
    });
 }



  addForm(payload) {
    id = id + 1;
    this.setState({
      items: id,
      grpOptDisabled: true
    });
    var newData = this.state.SampleData;
    if (payload.labelID.endsWith('-D'))
    newData.map((data,index)=>{
      if (data.labelID+'-D'=== payload.labelID)
        data.dupInd='Y';
      return true;
    })
    newData.push(payload);
    newData=_.sortBy(newData,'seqno');
    this.setState({ SampleData: newData, saveButtonEnabled:false }, () => {
      this.renderArrays("A", 0);
    });
  }


  // The following block of code retrieve all metadata from database to render the page.

  getAllCustomers = async () => {
    const response = await fetch("/api/getCustomerByID?key="+this.props.custid, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllUnits = async () => {
    const response = await fetch("/api/getUnit", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    //   console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllTemps = async () => {
    const response = await fetch("/api/getTemp", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleAddClick = () =>{
    this.setState({addButtonEnabled:true,  loadingButton: true})
    var currTS = moment();
    if (!prevTime) 
      prevTime=this.state.sampleDate;
      // this.getMaxSeq(this.state.custID,this.state.receiveDate).then (
      //   (res) => {
    var newData = {
      seqno: this.state.currentSeq,
      labelID:
        "FSRSUB" +
        "-" +
        currTS.format("YYMMDD") +
        "-" +
        this.state.custID.toString() +
        currTS.format("HHmmss"),
      analysisID: this.state.analysisID,
      UnitID: _.map(this.state.UnitID,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      TempID: _.map(this.state.TempID,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      analysisNM: this.state.analysisNM,
      analysisArea: this.state.defaultArea,
      UnitNM: _.map(this.state.UnitNM,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      TempNM: _.map(this.state.TempNM,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      SampleDate: prevTime,
      loc_cd: [],
      Desc: ""
    };
    let payload="("+newData.seqno+",'"
    +newData.labelID+"','"+this.props.custid+"','','"+newData.analysisID+"','"+newData.UnitNM+"','"+newData.TempNM
    +"',null,'"+newData.SampleDate+"','"+newData.Desc+"','PRESUBMIT','"+newData.UnitID+"','"+newData.TempID+"',null,"+newData.analysisArea+")"
    this.setState({prev_ref:parseInt(this.state.prev_ref)+1, currentSeq:parseInt(this.state.currentSeq)+1})
    console.log(newData);

    axios.post("/api/addSubmission",{payload:payload}).then(result => {
        this.setState({showOptionButton:true, addButtonEnabled:false,  loadingButton: false, testEditDisabled: true})
        if (result.status !== "ERROR" && result.data.status!=="ERROR") this.addForm(newData);
        else { this.errorpop.current.handleOpen(); console.log("error" + JSON.stringify(result)); }
      })
      .catch(function(error) {
        console.log(error);
      });
  // }
// )
// .catch(function(error){console.log(error)})
  }
  
  selectLoc = (data) => {
  let counter=this.state.locSelected;
  data.selected++; counter++;
   this.setState({allLoc:this.state.allLoc,locSelected:counter})
  }

  deselectLoc = (data) => {
    let counter=this.state.locSelected;
    data.selected--; counter--;
     this.setState({allLoc:this.state.allLoc,locSelected:counter}) 
  }
  
  filterLoc = () => {
    this.state.allLoc.forEach((data,index)=>{
      if (data.line_nm.toLowerCase().indexOf(this.state.filterString.toLowerCase())>=0 ||
      data.zone_nm.toLowerCase().indexOf(this.state.filterString.toLowerCase())>=0 ||
      data.loc_nm.toLowerCase().indexOf(this.state.filterString.toLowerCase())>=0 ||
      data.loc_desc.toLowerCase().indexOf(this.state.filterString.toLowerCase())>=0 ) data.show=true
      else data.show=false
    })
    this.setState({allLoc:this.state.allLoc}) 
  }

  confirmLoc = () =>  {
    this.setState({addButtonEnabled:true,  loadingButton: true,pickLocOpen:false})
    var currTS = moment();
    let tiebreaker=0;
    let payload="";
    let newDatas=[];
    let seq=this.state.currentSeq;
    // console.log(this.state.allLoc);
    this.state.allLoc.forEach((data,index)=>{
     if (data.selected>0) for (var i=0;i<data.selected;i++) {
      //  console.log(data);
      //  console.log(i);
          var newData = {
      seqno: seq++,
      labelID:
        "FSRSUB" +
        "-" +
        currTS.format("YYMMDD") +
        "-" +
        this.state.custID.toString() +
        currTS.format("HHmmss")+(tiebreaker++).toString(),
      analysisID: this.state.analysisID,
      UnitID: _.map(this.state.UnitID,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      TempID: _.map(this.state.TempID,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      analysisNM: this.state.analysisNM,
      analysisArea: this.state.defaultArea,
      UnitNM: _.map(this.state.UnitNM,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      TempNM: _.map(this.state.TempNM,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      SampleDate: this.state.sampleDate,
      Desc: data.loc_nm+' '+data.loc_desc,
      loc_cd: [data.loc_cd]
    };
    newDatas.push(newData);
    this.setState({currentSeq:seq,prev_ref:parseInt(this.state.prev_ref)+1});
    payload=(payload===""?"":payload+",")+"("+newData.seqno+",'"
    +newData.labelID+"','"+this.props.custid+"','','"+newData.analysisID+"','"+newData.UnitNM+"','"+newData.TempNM
    +"',null,'"+newData.SampleDate+"','"+newData.Desc.replace("\\","\\\\").replace("'","\\'")+"','PRESUBMIT','"
    +newData.UnitID+"','"+newData.TempID+"','"+data.loc_cd+`',${newData.analysisArea})`
    }
    data.selected=0;}
    )
    console.log('DB start '+moment());
    axios.post("/api/addSubmission",{payload:payload}).then(
      (result)=>{
        // console.log(result);
        console.log('DB done '+moment());
        this.setState({showOptionButton:true,addButtonEnabled:false,  loadingButton: false, testEditDisabled: true,locSelected:0})
            if (result.data.status !== "ERROR") {
              var newData = this.state.SampleData;
              newDatas.forEach((value,index)=>
          {
            id = id + 1;
            if (value.labelID.endsWith('-D'))
            newData.forEach((data,index)=>{
              if (data.labelID+'-D'=== value.labelID)
                data.dupInd='Y'
            })
            newData.push(value);
          });
          newData=_.sortBy(newData,'seqno');
          this.setState({ SampleData: newData, saveButtonEnabled:false, items: id,
            grpOptDisabled: true }) 
        }
            else { this.errorpop.current.handleOpen(); console.log("error" + JSON.stringify(result)); }
      }
    )

  }

  // initialize an empty start point or load from draft

  initialize() {

    if (this.props.custid===33)
    this.setState({analysisID: ["t31"],
    UnitID: ["u1"],
    TempID: ["p1"],
    analysisNM: [_.where(this.state.testOptions, { value: "t31" })[0].text],
    UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
    TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});
    else 
    if (this.props.custid===130 || this.props.custid===131 || this.props.custid===133 )
    this.setState({analysisID: ["t31"],
    UnitID: ["u1"],
    TempID: ["p1"],
    analysisNM: [_.where(this.state.testOptions, { value: "t31" })[0].text],
    UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
    TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});
    else
    this.setState({analysisID: ["t0"],
    UnitID: ["u1"],
    TempID: ["p1"],
    analysisNM: [this.state.testOptions[0].text],
    UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
    TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});

   
    axios.get("/api/querySubmission?custid="+this.props.custid).then(result=>{
      if (result.data.length>0) 
      {
        this.setState({custID:this.props.custid});
            var newData=[];
    result.data[0].unit_id.split(",").forEach((data,index)=>{
      console.log(_.where(this.state.unitOptions,{value:data}).length);
      if (_.where(this.state.unitOptions,{value:data}).length===0)
      {var oldUnits = this.state.unitOptions;
      oldUnits.push({ text: data, value:data });
      // console.log(oldUnits);
      this.setState({
        unitOptions: oldUnits
      });
    }
    })

        result.data[0].temp_id.split(",").forEach((data,index)=>{
      if (_.where(this.state.tempOptions,{value:data}).length===0)
      {    var oldTemp = this.state.tempOptions;
        oldTemp.push({ text: data, value:data });
        this.setState({
          tempOptions: oldTemp
        });
    }
    })
    for (var i in result.data)
      { var testOptions=(this.state.testOptions);
        var dupInd='N';
        // this.state.allLoc.map((data,index)=>{
        //   if (data.loc_cd===result.data[i].loc_cd) data.selected=true;
        // })
        console.log(result.data[i].loc_cd);
        if (_.where(result.data,{sample_ID:result.data[i].sample_ID+'-D'}).length>0) dupInd='Y';
         newData.push ({
           seqno: result.data[i].seqid,
          labelID: result.data[i].sample_ID,
          analysisArea: result.data[i].area_size,
        analysisID: result.data[i].test.split(","),
        UnitID: result.data[i].unit_id.replace("\\","\\\\").replace("'","\\'").split(","),
        TempID: result.data[i].temp_id.replace("\\","\\\\").replace("'","\\'").split(","),
        analysisNM: _.map(result.data[i].test.split(","), function(tid){ 
          return _.where(testOptions, {
         value: tid
        })[0].text}),
        UnitNM: result.data[i].unit.replace("\\","\\\\").replace("'","\\'").split(","),
        TempNM: result.data[i].temp.replace("\\","\\\\").replace("'","\\'").split(","),
        SampleDate: moment(result.data[i].sample_date).format("YYYY-MM-DD HH:mm"),
        Desc: result.data[i].desc,
        // loc_cd:Array.isArray(result.data[i].loc_cd)?result.data[i].loc_cd:[result.data[i].loc_cd],
        loc_cd:result.data[i].loc_cd?_.map(result.data[i].loc_cd.split(','),function(loc){return parseInt(loc)}):[],
        dupInd: dupInd
         }      );
      }
  this.setState({analysisID: newData[0].analysisID,
    UnitID: result.data[0].unit_id.split(","),
    TempID: result.data[0].temp_id.split(","),
    analysisNM: newData[0].analysisNM,
    UnitNM: result.data[0].unit.split(","),
    TempNM: result.data[0].temp.split(",")});
console.log(newData);
    this.setState({SampleData:newData,grpOptDisabled:true,  showOptionButton:true, testEditDisabled:true, loaderActive:false,
      currentSeq: _.max(_.pluck(newData,'seqno'))+1, saveButtonEnabled: false});

      }
      this.setState({loaderActive:false})
    })
  

  }


  componentWillMount() {
    this.setState({loaderActive:true});
    this.setState({custID:this.props.custid});
    var locOptions=[];
    console.log(this.props.custid);
    axios.get("/api/getLoc?custid="+this.props.custid).then(res=>{
      // console.log(res);
      if (res.data.STATUS!=='ERROR')
      { res.data.forEach((value,index)=>{
          locOptions.push({key:value.loc_cd,value:value.loc_cd,text:value.loc_nm});
          res.data[index].selected=0;
          res.data[index].show=true;
        })
        // console.log(res.data);
        this.setState({allLoc:_.sortBy(res.data, 'loc_nm')})
      }
       else
       this.errorpop.current.handleOpen();
      this.setState({locOptions:_.sortBy(locOptions, 'text')});
    })

    this.getAllCustomers().then(res => {
      var cust=res;
      console.log(cust);
      this.setState({ custOptions: res });
      this.getAllTests().then(res => {
        console.log(res);
        // var testOptions=[];
        // for (var item in res) {
        //   console.log(item);
        //   if (res[item].text.match("^Listeria"))
        //    testOptions.push(res[item]);
        // }
        if (cust[0].key===33)
         var testOptions=_.filter(res,function(item){return (item.key!==0 && item.key!==4) })
        else if (cust[0].key===130 || cust[0].key===131 || this.props.custid===133)
         var testOptions=_.filter(res,function(item){return (item.key!==0 && item.key!==4) })
         else 
        var testOptions=res;

      //  console.log(_.filter(res,function(item){return item.text.startsWith("Listeria")}));
      this.setState({ testOptions: testOptions});
      // console.log(res);
       this.getAllUnits().then(res => {
      this.setState({ unitOptions: res });
        this.getAllTemps().then(res => {
      this.setState({ tempOptions: [
        { key: "p1", value: "p1", text: "<=40F" },
        { key: "p2", value: "p2", text: ">40F" },
        { key: "p3", value: "p3", text: "On Ice" }
      ]},()=>this.initialize());
      
    });
  });
});
  });
    // console.log("before mount:" + JSON.stringify(this.state.SampleData));
   // this.initialize()
  }

  scrollToBottom() {
    //  console.log(this.myRef.current);
    this.myRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }

  componentDidUpdate() {
    //  console.log(justadded);
    if (justadded) {
      this.scrollToBottom();
    }
  }

  saveOption = () =>{
    console.log(this.state.UnitID);
    console.log(this.state.analysisID);
    console.log(this.state.TempID);
    console.log(this.state.SampleData);
    var newData=this.state.SampleData;
    var updPromises=[];
    newData.map((value,index)=>{
    newData[index].analysisID=this.state.analysisID;
    newData[index].analysisNM=this.state.analysisNM;
    newData[index].UnitID=this.state.UnitID;
    newData[index].UnitNM=this.state.UnitNM;
    newData[index].TempNM=this.state.TempNM;
    newData[index].TempID=this.state.TempID;
    newData[index].SampleDate=moment(this.state.sampleDate).format('YYYY-MM-DD')+' '+moment(newData[index].SampleDate).format('HH:mm')
    var payload={
      id: newData[index].labelID,
      custid: this.state.custID,
      userid: this.state.userKey,
      testid: newData[index].analysisID,
      unitNM: newData[index].UnitNM,
      tempNM: newData[index].TempNM,
      recvDT: this.state.receiveDate,
      sampleDT: newData[index].SampleDate,
      desc: (newData[index].Desc?newData[index].Desc:'').replace("\\","\\\\").replace("'","\\'"),
      //desc: newData[key].Desc,
      unitID: newData[index].UnitID,
      tempID: newData[index].TempID,
      analysisArea: newData[index].analysisArea,
      loc_cd:newData[index].loc_cd.join(','),
      seqno: parseInt(newData[index].seqno)
    }
    updPromises.push(axios.post("/api/updSubmissionOpts",payload));
  })
    console.log(newData);
    Promise.all(updPromises)
  .then(result => {
    var status="SUCCESS";
    result.map((data)=>{
      if (data.data.status==='ERROR') status="ERROR"
    })
    if (status !== "ERROR")  
      this.setState({grpOptDisabled:true,addButtonEnabled:false, testEditDisabled:true,disableChangeOpt:false,
      disableSaveOpt:true,saveButtonEnabled:false,SampleData:newData})
    else 
     { console.log("error" + JSON.stringify(result));
     this.errorpop.current.handleOpen();
    //  this.delSample(key);
    }
  })
  .catch(function(error) {
    console.log(error);
  });

  }

  render() {
  //  console.log(this.state.custID);
  //  console.log(this.state.custOptions);
  let changeOptButton=this.state.showOptionButton?(<Button
    primary
    hidden
    disabled={this.state.disableChangeOpt}
    onClick={()=>this.setState({grpOptDisabled:false,addButtonEnabled:true, testEditDisabled:false, 
      disableChangeOpt:true,disableSaveOpt:false,saveButtonEnabled:true})}
  >
   Change Option
  </Button>):"";
let saveOptButton=this.state.showOptionButton?(<Button
  primary
  hidden
  disabled={this.state.disableSaveOpt}
  onClick={()=>this.saveOption()}
>
 Save Option
</Button>):"";


    
    var testGroup=this.state.analysisID.map((data,index)=>{
      var addButton='';
      if (index===this.state.analysisID.length-1) 
       addButton=
          <Form.Button className='formButton' icon circular width={1} color='blue'
          onClick={this.addTest} disabled={this.state.testEditDisabled}> <Icon name='add' /> </Form.Button>
       else  addButton=
       <Form.Button className='formButton' icon circular width={1} color='red' disabled={this.state.testEditDisabled}
       onClick={(e)=>this.removeTest(e,index)}> <Icon name='minus' /> </Form.Button>

      return (
        <Form.Group key={"fm_grp_"+index} >
        <Form.Field
            control={Dropdown}
            label="Select Test"
            placeholder="Select Test"
            fluid
            selection
            search
            width={3}
            options={this.state.testOptions}
            value={this.state.analysisID[index]}
            onChange={(e, data) => this.pickAnalysis(data,index)}
            disabled={this.state.grpOptDisabled} 
          />
          <Form.Field
            control={Dropdown}
            label="Unit"
            placeholder="Select Unit"
            width={5}
            fluid
            selection
            search
            allowAdditions
            options={this.state.unitOptions}
            value={this.state.UnitID[index]}
            onAddItem={this.AddUnit}
            onChange={(e, data) => this.pickUnit(data,index)}
            disabled={this.state.grpOptDisabled} 
          />
          <Form.Field
            control={Dropdown}
            width={5}
            label="Temp"
            placeholder="Select Temp"
            fluid
            selection
            search
            allowAdditions
            options={this.state.tempOptions}
            value={this.state.TempID[index]}
            onAddItem={this.AddTemp}
            onChange={(e, data) => this.pickTemp(data,index)}
            disabled={this.state.grpOptDisabled} 
          />
          {addButton}
          </Form.Group>
      )
    })

    
      
    var databody=this.state.SampleData.map((item, i) => {
      var copyIcon='';
  //  if (item.dupInd!=='Y' && !item.labelID.endsWith('-D'))
  //        copyIcon=<Button
  //        color="green"
  //        className="copyButton"
  //        onClick={
  //        e => this.handleCopyClick(item)
  //        }
  //        icon
  //      >
  //        <Icon name="copy" />
  //      </Button>;

     return    <Table.Row key={"c" + i}>
    <Table.Cell>
     <Input size='small' value={this.state.SampleData[i].seqno}
     disabled={this.state.addButtonEnabled}
     onChange={(e,data)=>this.saveSeq(e,data,item,i)} 
     onBlur={e => this.commitChange(e, item, i)}/>
     </Table.Cell>
     <Table.Cell>{this.state.SampleData[i].labelID}</Table.Cell>
     <Table.Cell>
     <Input size='small'> <Flatpickr
                     label="Sample Time"
                     disabled={this.state.addButtonEnabled}
                     options={{ defaultDate: moment(this.state.sampleDate).format('HH:mm'),
                     enableTime: true,
                     noCalendar: true}}
                     value={moment(this.state.SampleData[i].SampleDate).format('HH:mm')}
                     onChange={(e, data) => this.saveTime(data, item, i)}
                    //  onClose={e => this.commitChange(e, item, i)}
                    />
                     </Input>
     </Table.Cell>
     <Table.Cell>
      <Dropdown disabled={this.state.addButtonEnabled} 
      clearable search multiple selection options={this.state.locOptions} value={item.loc_cd}
      onChange={(e,data)=>this.changeLoc(e,data,item)}
      onClose={(e,data) => this.commitChange(e, item, i)}/>
     </Table.Cell>
     <Table.Cell>
     <Input size='small' value={this.state.SampleData[i].analysisArea}
                         disabled={this.state.addButtonEnabled}
                         onChange={(e, data) => this.saveArea(e, data, item, i)}
                         onBlur={e => this.commitChange(e, item, i)}/>
     </Table.Cell>
     <Table.Cell><TextArea rows={1} autoHeight
                    placeholder="Description for the sample"
                    disabled={this.state.addButtonEnabled}
                    value={this.state.SampleData[i].Desc}
                    onChange={(e, data) => this.saveDesc(e, data, item, i)}
                    onBlur={e => this.commitChange(e, item, i)} /></Table.Cell>
     <Table.Cell>    <div className="actionArea">  <Button
               color="red"
               className="deleteButton"
               disabled={this.state.addButtonEnabled}
               onClick={
               e => this.showConfirmDelete(i)
               }
               icon
             >
               <Icon name="delete" />
             </Button>
           {copyIcon}
             </div>
             </Table.Cell>
     </Table.Row>
   });
   var detailTable=
   <Table celled size='small'>
   <Table.Header>
     <Table.Row>
     <Table.HeaderCell width={1}>Form Number</Table.HeaderCell>
      <Table.HeaderCell width={2}>Submission ID</Table.HeaderCell>
       <Table.HeaderCell width={2}>Sample Time</Table.HeaderCell>
       <Table.HeaderCell width={2}>Sample Location</Table.HeaderCell>
       <Table.HeaderCell width={1}>Sample Area</Table.HeaderCell>
       <Table.HeaderCell width={6}>Additional Description </Table.HeaderCell>
       <Table.HeaderCell width={2}>Action</Table.HeaderCell>
     </Table.Row>
   </Table.Header>
   <Table.Body>
 {databody}
</Table.Body>
</Table>;
let counter=0;
let bodyData=this.state.allLoc.map((data,index)=>{
  let selectedIcon= <Icon circular color='green' name='plus' onClick={()=>this.selectLoc(data)}/>;
  let deselectIcon= <Icon className='minusicon' circular color='grey' name='minus'/>;
  if (data.selected>0) 
  {deselectIcon= <Icon className='minusicon'  circular color='red' name='minus' onClick={()=>this.deselectLoc(data)}/>;
counter+=data.selected;
}

if (data.show) 
  return <Table.Row key={'datarow'+index} active={data.selected>0?true:false}> 
    <Table.Cell className='selectCell'>{selectedIcon}{data.selected}{deselectIcon}</Table.Cell>
  <Table.Cell>{data.site_nm}</Table.Cell>
  <Table.Cell>{data.line_nm}</Table.Cell>
  <Table.Cell>{data.zone_nm}</Table.Cell>
  <Table.Cell>{data.loc_nm}</Table.Cell>
  <Table.Cell>{data.loc_desc}</Table.Cell>
  </Table.Row>
  else return null
})


var LocationModal=<Modal 
size="fullscreen"
open={this.state.pickLocOpen}
onClose={()=>{this.setState({pickLocOpen:false})}}
closeOnDimmerClick={false}>
        <Modal.Header>Select locations to be sampled</Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
           <Header as='h3'> 
           Please select the location that you want to send samples for. 
           If you don't have location defined yet, please click cancel or confirm. 
           You can always change location in next screen. 
           </Header>
           </Modal.Description>
           <Input action= {{ content:'Search', 
           onClick:()=>this.filterLoc()
          }} fluid placeholder='Use keyword to filter the location list...' 
           value={this.state.filterString} 
           onKeyPress={(e)=>{
             console.log(e.key)
             if (e.key === 'Enter' ) this.filterLoc()
           }}
           onChange={(e,data)=>this.setState({filterString:data.value})}
           />
           <Table basic size='small' className='locPickTbl'>
    <Table.Header>
      <Table.Row>
      <Table.HeaderCell width={1}>Selected</Table.HeaderCell>
        <Table.HeaderCell width={2}>Site</Table.HeaderCell>
        <Table.HeaderCell  width={2}>Line</Table.HeaderCell>
        <Table.HeaderCell  width={2}>Zone</Table.HeaderCell>
        <Table.HeaderCell width={2} >Location</Table.HeaderCell>
        <Table.HeaderCell width={8} >Location Description</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
        {bodyData}
    </Table.Body>
    </Table>
           </Modal.Content>
           <Modal.Actions>
             {counter + " Locations Selected" }
                  <Button icon secondary onClick={()=>{this.setState({pickLocOpen:false})}}>
                    Cancel
                  </Button>
                  <Button disabled={counter<=0} icon primary onClick={()=>this.confirmLoc()}>
                    Confirm <Icon name="check" />
                  </Button> 
                </Modal.Actions>
</Modal>


 var saveModal="";
 if (this.state.saveStep===1)
 saveModal=<Modal 
 size="large"
 open={this.state.saveConfirm}
 onClose={()=>{this.setState({saveConfirm:false})}}
 closeOnDimmerClick={false}>
         <Modal.Header>Sample Submission Confirmation</Modal.Header>
                 <Modal.Content scrolling>
                   <Modal.Description>
            <Header as='h3'> 
Please confirm the following information for your submission.
            </Header>
            <Header as='h5'> 
Total Samples submitted: {this.state.SampleData.length}
            </Header>
            <Header as='h5'> 
Test Submitted for: {this.state.SampleData[0].analysisNM.join(", ")}
            </Header>
            </Modal.Description>
            <CommentSection 
        value={this.state.submissionComment}
          onChange={(value) => this.setState({ submissionComment: value })}
    />
            </Modal.Content>
            <Modal.Actions>
                   <Button icon secondary onClick={()=>this.setState({saveButtonEnabled:false, saveConfirm:false, saveStep: 0})}>
                     Cancel
                   </Button>
                   <Button icon primary loading={this.state.loadingSave} onClick={()=>this.submitSample()}>
                     Confirm <Icon name="check" />
                   </Button> 
                 </Modal.Actions>
 </Modal>
 else if (this.state.saveStep===2) 
 saveModal=<Modal 
  size="large"
  open={this.state.saveConfirm}
  onClose={()=>{this.setState({saveConfirm:false})}}
  closeOnDimmerClick={false}>
          <Modal.Header>Sample Form Download</Modal.Header>
                  <Modal.Content scrolling>
                    <Modal.Description>
             <Header as='h3'> 
The samples have been submitted successfully.<br/> Please download and print out the attached sample form and send with the samples
             </Header>
             </Modal.Description>
             </Modal.Content>
             <Modal.Actions>
             <Button icon secondary onClick={()=>this.setState({saveConfirm:false, saveButtonEnabled:false, saveStep: 0})}>
                      Close <Icon name="check" />
                    </Button> 
                    <Button primary onClick={()=>this.downloadForm()}>
        Download <Icon name='download' />
      </Button>
                  </Modal.Actions>
  </Modal>
  else if (this.state.saveStep===3) 
  saveModal=<Modal 
   size="large"
   open={this.state.saveConfirm}
   onClose={()=>{this.setState({saveConfirm:false})}}
   closeOnDimmerClick={false}>
           <Modal.Header>Sample Form Download</Modal.Header>
                   <Modal.Content scrolling>
                     <Modal.Description>
              <Header as='h3'> 
 The samples have been submitted successfully.<br/>  But issues happened during form generation, Please contact the lab for help. </Header>
              </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                     <Button icon primary onClick={()=>this.setState({saveConfirm:false, saveStep: 0})}>
                       Confirm <Icon name="check" />
                     </Button> 
                   </Modal.Actions>
   </Modal>

    return (
      <Segment className='mainseg'>
       <Dimmer inverted active={this.state.loaderActive}>
        <Loader inverted>Loading</Loader>
      </Dimmer>
        <Form className="receiveForm">
          <Form.Group widths="equal">
            <Form.Field
              control={Dropdown}
              label="Customer"
              placeholder="Select Customer"
              width={6}
              search
              selection
              options={this.state.custOptions}
              onChange={this.pickCustomer}
              value={this.state.custID.toString()}
              disabled={this.state.grpOptDisabled} 
            />
            {/* <Form.Field
              className="userName"
              control={Input}
              label="Technician Name"
              width={3}
              transparent
              value={this.state.userID}
              action={<Button icon onClick={this.logout}> <Icon name='power off' />   </Button>}
            /> */}
            {/* <Form.Field
              control={Flatpickr}
              label="Receive Date"
              options={{ defaultDate: moment().format("YYYY-MM-DD") }}
              width={3}
              value={this.state.receiveDate}
              onChange={(e, data) => this.saveRecvDate(data)}
              disabled={this.state.grpOptDisabled} 
            /> */}
              <Form.Field
              control={Flatpickr}
              width={3}
              label="Sample Date"
              options={{ defaultDate: this.state.sampleDate }}
              // data-enable-time
              value={this.state.sampleDate}
              onChange={(e, data) => this.saveSampleDate(data)}
              disabled={this.state.grpOptDisabled} 
            />
              <Form.Field
              control={Input}
              width={2}
              label="Default Area"
              value={this.state.defaultArea}
              onChange={(e, data) => this.updateAnalysisArea(data)}
              disabled={this.state.grpOptDisabled} 
            /> 
          </Form.Group>
         {testGroup}
            {detailTable}
          <Confirm open={this.state.confirmDeleteOpen} content='This entry will be deleted permanently' 
              onCancel={this.handleCancelDelete} onConfirm={this.handleConfirmDelete} />
           <Button
            primary
            disabled={this.state.addButtonEnabled}
            onClick={()=>{this.setState({pickLocOpen:true,locSelected:0})}}
            loading={this.state.loadingButton}
            // loading={this.state.loadingButton}
          >
            Add from Master List
          </Button>
          <Button
            primary
            disabled={this.state.addButtonEnabled}
            onClick={this.handleAddClick}
            loading={this.state.loadingButton}
          >
            Add Single Sample
          </Button>
          {changeOptButton}
          {saveOptButton}
           <Button
            color="green"
            disabled={this.state.saveButtonEnabled}
            loading={this.state.loadingSave}
            onClick={this.handleSaveClick}
          >
            Submit
          </Button>
          <Button
            secondary
            // disabled={this.state.cancelButtonEnabled}
            onClick={this.showConfirmCancel}
          >
            Cancel
          </Button>
        </Form>

        <Confirm open={this.state.confirmResetOpen} content='This action will delete all entries that have not been saved yet.' 
              onCancel={this.handleCancelCancel} onConfirm={this.handleConfirmCancel} />        
        <Errorpop ref={this.errorpop} />
        <div style={{ float: "left", clear: "both" }} ref={this.myRef} />
        {LocationModal}
        {saveModal}
        </Segment>
    );
  }
}
